<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="query">
        <div class="left" style="margin-bottom: 16px">
          <div class="label">课程名称</div>
          <a-input v-model="form.name" placeholder="请输入" style="flex: 1;margin-right: 8px"></a-input>
          <a-space>
            <a-button @click="query" type="primary">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>

          <a-button
            style="margin-left: 240px"
            type="primary"
            @click="$router.push('/knowledge/study/me')"
          >我的学习</a-button>
        </div>

        <div class="left">
          <div class="label">当前位置</div>
          <span v-for="(item, index) in selectedPosition" :key="item.id">
            <a-dropdown>
              <a @click="e => e.preventDefault()">
                {{ item.name }}
                <a-icon type="right" v-if="index !== selectedPosition.length - 1" />
              </a>
              <a-menu slot="overlay" v-if="Array.isArray(item.children)">
                <a-menu-item v-for="child in item.children" :key="child.id">
                  <a href="#" @click.prevent="select(index, child)">{{child.name}}</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </div>
      </div>

      <Padding />

      <div v-if="list.length > 0">
        <div class="list">
          <div class="item" v-for="item in list" :key="item.id" @click="open(item)">
            <img class="avatar" v-if="item.cover" :src="item.cover.completePath" alt="图片" />
            <div class="name" :title="item.name">{{ item.name }}</div>
            <div class="desc">{{ item.remark }}</div>
            <div class="footer">
              <span>{{ item.creator }}</span>
              <span>{{ item.createAt }}</span>
            </div>
          </div>
        </div>

        <div class="right" style="margin: 24px 0 12px">
          <a-pagination
            :current="current"
            :pageSize="pageSize"
            :total="total"
            :showTotal="(total) => `共 ${total} 条记录`"
            @change="onChange"
          />
        </div>
      </div>

      <div v-else style="padding: 48px">
        <a-empty />
      </div>

      <a-modal title="课程章节" :visible="visible" @cancel="cancel" :footer="null" width="600px">
        <a-table
          bordered
          :data-source="chapters"
          :loading="detailLoading"
          :pagination="false"
          rowKey="id"
        >
          <a-table-column title="章节名称" data-index="name"></a-table-column>

          <a-table-column title="章节类型" align="center" width="80px">
            <template slot-scope="text">
              <a-tag color="green" v-if="text.type === 'course'">课程</a-tag>
              <a-tag color="blue" v-if="text.type === 'exam'">考试</a-tag>
            </template>
          </a-table-column>

          <a-table-column title="学习时长(分钟)" data-index="duration" align="center"></a-table-column>
        </a-table>

        <Padding size="large" />

        <div class="right">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button type="primary" :loading="studyLoading" @click="onStartStudy">开始学习</a-button>
          </a-space>
        </div>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import study from "@/mixins/study";

function fetchList(params) {
  return request({
    url: "/office-service/study/course/list",
    params
  });
}

function fetchDetail(params) {
  return request({
    url: "/office-service/study/course/query/" + params.id
  });
}

function firstStudy(data) {
  return request({
    url: "/office-service/study/user/play/request",
    method: "post",
    data
  });
}

export default {
  mixins: [watermark, study],

  data() {
    return {
      selectedPosition: [
        {
          id: "root",
          name: "根目录",
          children: this.useableTree
        }
      ],

      form: {},

      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 12,

      detail: {},
      visible: false,
      detailLoading: false,

      studyLoading: false
    };
  },

  watch: {
    useableTree() {
      if (this.useableTree) {
        this.selectedPosition = [
          {
            id: "root",
            name: "根目录",
            children: this.useableTree
          }
        ];
      }
    }
  },

  computed: {
    chapters() {
      return Array.isArray(this.detail.chapters) ? this.detail.chapters : [];
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    select(index, item) {
      if (index !== this.selectedPosition.length - 1) {
        this.selectedPosition.splice(
          index + 1,
          this.selectedPosition.length,
          item
        );
      } else {
        this.selectedPosition.push(item);
      }

      this.getList();
    },
    getList() {
      const hide = this.$message.loading("加载中...", 0);

      let indexName;
      if (this.selectedPosition.length > 1) {
        const [, ...rest] = this.selectedPosition;
        indexName = rest.map(item => item.name).join("/");
      }

      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form,
        indexName,
        status: 1
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          hide();
        });
    },

    onChange(current) {
      this.current = current;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.current = 1;
      this.form = {};
      this.selectedPosition = [
        {
          id: "root",
          name: "根目录",
          children: this.useableTree
        }
      ];
      this.getList();
    },

    open(item) {
      this.visible = true;
      this.detailLoading = true;
      fetchDetail({
        id: item.id
      })
        .then(res => {
          this.detail = res || {};
        })
        .finally(() => {
          this.detailLoading = false;
        });
    },

    cancel() {
      this.detail = {};
      this.visible = false;
    },

    onStartStudy() {
      if (
        Array.isArray(this.detail.chapters) &&
        this.detail.chapters.length > 0
      ) {
        this.studyLoading = true;

        firstStudy({
          courseId: this.detail.id,
          chapterId: this.detail.chapters[0].id
        })
          .then(res => {
            if (res) {
              const id = this.detail.id;
              this.cancel();

              this.$router.push(`/knowledge/study/detail?courseId=${id}`);
            }
          })
          .finally(() => {
            this.studyLoading = false;
          });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.query {
  padding: 8px;

  .label {
    margin-right: 8px;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;

  .item {
    cursor: pointer;
    border: 1px solid #f0f0f0;
    display: flex;
    flex-direction: column;
    .avatar {
      display: block;
      width: 100%;
      height: 23vh;
      object-fit: cover;
    }

    .name {
      font-weight: bold;
      padding: 8px;
      font-size: 14px;
    }
    .desc {
      margin-top: auto;
      color: #999;
      padding: 0 8px;
    }
    .footer {
      color: #999;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@media (min-width: 800px) {
  .list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1300px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1800px) {
  .list {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>